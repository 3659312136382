<template>
<div id="app">
    <div class="container position-sticky z-index-sticky top-0">
        <div class="row">
            <div class="col-12">
                <Nav :key="$route.fullPath" /> <!-- change key in Nav on logout -->
            </div>
            <Toast position="top-right"/> <!-- Global Toast -->
        </div>
    </div>
    <router-view style="padding-top: 40px" />
    <Footer />
</div>
</template>

<script>
import {
    provide,
    getCurrentInstance
} from "vue";
import Nav from "./components/partials/Nav.vue";
import Footer from "./components/partials/Footer.vue";

import {
    CSCL_SERVICE
} from "./services/cscl-service.interface";
import {
    MockCsclService
} from "./services/mock-cscl-service";
import {
    CsclService
} from "./services/cscl-service";
import { ANNOTATION_SERVICE } from "./services/annotation-service.interface";
import { RecogitoAnnotationService } from "./services/recogito-annotation-service";

import { QGEN_SERVICE } from "./services/qgen-service.interface";
import { MockQGenService } from "./services/mock-qgen-service";
import { QGenService } from "./services/qgen-service";

import { TEXT_ANALYSIS_SERVICE } from "./services/text-analysis-service.interface";
import { TextAnalysisService } from "./services/text-analysis-service";

import {
    TOAST_SERVICE
} from "./services/toast-service.interface";
import {
    PrimeVueToastService
} from "./services/prime-vue-toast-service";

import {
    createApp
} from 'vue';
import {
    defineComponent
} from "vue";

import { DATASETS_SERVICE } from "./services/datasets-service.interface";
import { DatasetsService } from "./services/datasets-service";

export default {
    name: "app",
    components: {
        Nav,
        Footer,
    },
    setup() {
        const app = getCurrentInstance().appContext.app;
        /**
         * Services injected via DI.
         */
        provide(CSCL_SERVICE, new CsclService());
        //provide(CSCL_SERVICE, new MockCsclService());
        provide(DATASETS_SERVICE, new DatasetsService());
        provide(TOAST_SERVICE, new PrimeVueToastService(app));
        provide(ANNOTATION_SERVICE, new RecogitoAnnotationService());
        provide(QGEN_SERVICE, new QGenService());
        provide(TEXT_ANALYSIS_SERVICE, new TextAnalysisService());
  },
};
</script>

<style lang="scss">
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
html, body {
    overscroll-behavior: none;
}
</style>
